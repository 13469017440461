import { default as React, useEffect, useRef, useState } from 'react';
import { TimeAgo } from '../components/TimeAgo';
import { Strapi } from '../functions/Strapi';
import { useFetchFromStrapi } from '../hooks/useFetchFromStrapi';
import "./FinishList.scss";

// filters: {
//     ownArticle: { $eq: true },
//     title: { $containsi: searchTerm } // case-insensitive Suche
// },
const FinishList = ({
    route = "/articles",
    pageSize = 10
}) => {
    // Lokale States
    const [articles, setArticles] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);         // Aktuelle Seite (Infinite Scroll)
    const [searchTerm, setSearchTerm] = useState("");

    // Refs für Auto-Refresh-Timer
    const autoRefreshTimeoutRef = useRef(null);
    const autoRefreshIntervalRef = useRef(null);

    // Build query config inkl. Filter, Suche und Pagination
    const queryConfig = {
        sort: ['updatedAt:DESC'],
        populate: ['author', 'audio', 'poll', 'titleImage', 'events'],
        filter: {
            title: { $containsi: searchTerm } // case-insensitive Suche
        },
        pagination: { page, pageSize }
    };

    // Hook-Aufruf zur Datenabfrage
    const { data, loading, error, refetch, limit } = useFetchFromStrapi(
        route,
        queryConfig,
    );

    // Bei neuen Daten: Bei Seite 1 Liste ersetzen, sonst anhängen
    useEffect(() => {
        if (data) {
            if (page === 1) {
                setArticles(data);
            } else {
                setArticles(prev => [...prev, ...data]);
            }
            setCount(limit);
        }
    }, [data, limit]);

    // Bei neuer Suche Seite 1 setzen
    useEffect(() => {
        setPage(1);
    }, [searchTerm]);

    // Infinite Scroll: Beim Erreichen des Seitenendes nächste Seite laden
    useEffect(() => {
        const handleScroll = () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 10) {
                if (articles.length < count) {
                    setPage(prev => prev + 1);
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [articles, count]);

    // Timer stoppen
    const stopAutoRefresh = () => {
        if (autoRefreshTimeoutRef.current) {
            clearTimeout(autoRefreshTimeoutRef.current);
            autoRefreshTimeoutRef.current = null;
        }
        if (autoRefreshIntervalRef.current) {
            clearInterval(autoRefreshIntervalRef.current);
            autoRefreshIntervalRef.current = null;
        }
    };

    // Auto-Refresh: Nach 30s Inaktivität (ganz oben) alle 5s aktualisieren (nur Seite 1)
    const startAutoRefresh = () => {
        stopAutoRefresh();
        autoRefreshTimeoutRef.current = setTimeout(() => {
            autoRefreshIntervalRef.current = setInterval(() => {
                if (window.scrollY === 0 && page === 1) {
                    refetch();
                } else {
                    stopAutoRefresh();
                }
            }, 5000);
        }, 30000);
    };

    // Scroll-Listener für Auto-Refresh
    useEffect(() => {
        const handleAutoRefresh = () => {
            if (window.scrollY === 0) {
                if (!autoRefreshTimeoutRef.current && !autoRefreshIntervalRef.current) {
                    startAutoRefresh();
                }
            } else {
                stopAutoRefresh();
            }
        };
        window.addEventListener('scroll', handleAutoRefresh);
        handleAutoRefresh(); // initial prüfen
        return () => {
            window.removeEventListener('scroll', handleAutoRefresh);
            stopAutoRefresh();
        };
    }, [page]);

    if (error) return <p>Error loading articles.</p>;

    return (
        <div className='FinishList p-3 relative'>
            <h2 className="text-lg font-semibold mb-4">Completed Articles ({count})</h2>
            {/* Suchleiste */}
            <input
                className="border p-2 mb-3 w-full"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {loading && <p>Loading...</p>}
            {/* Liste der Artikel */}
            {articles.map((item) => (
                <div>
                    <ArticleItem key={item.id} {...item} />
                </div>
            ))}
        </div>
    );
};

const formatTimeAgo = (date) => {
    const diff = Math.floor((new Date() - new Date(date)) / 1000);
    return diff + " seconds ago";
};

export default FinishList;

function ArticleItem({ id, attributes }) {
    let { title, slug, publicTimestamp, publishedAt, createdAt, author = {}, titleImage, titleImageObject, events, version } = attributes;
    const [timeAgo, setTimeAgo] = useState(formatTimeAgo(publishedAt));
    const [Clicked, setClicked] = useState(false);

    let imageSmallUrl = titleImageObject?.data?.attributes?.formats?.thumbnail?.url || false;

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(formatTimeAgo(publishedAt));
        }, 1000);

        return () => clearInterval(interval);
    }, [publishedAt]);

    let authorSlug = author?.data?.attributes?.slug;
    if (!slug) return <p>No SLUG!!! ID: {id}</p>
    if (!authorSlug) return <p>No authorSlug!!! ID: {id}</p>
    let url = "https://mittelhessen.app/p/" + authorSlug + "/" + slug;

    function handleClick() {
        setClicked(true);
    }

    return <div className={'item ' + (Clicked && "clicked")}>
        <div className='d-flex flex-row'>

            {imageSmallUrl && <img style={{ width: 64, height: 64 }} src={Strapi.MAIN_ADDRESS + imageSmallUrl} alt="Article Thumbnail" />}

            <a href={url} target='_blank' rel="noopener noreferrer" onClick={handleClick} className='d-flex flex-row'>
                <div>
                    <span className='title'>{title}</span>
                    <TimeAgo title="Internal Public Date">{publishedAt}</TimeAgo>
                    <div className='d-flex flex-row'>
                        <span className='version'>{version}</span>
                    </div>
                    <HighlightedItems data={attributes} />
                </div>
            </a>
        </div>
    </div>
}

function isValidValue(value) {
    if (value === null || value === false || value === "false") return false;
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (typeof value === 'object') {
        if (value.hasOwnProperty('data')) {
            if (Array.isArray(value.data)) {
                return value.data.length > 0;
            }
            return isValidValue(value.data);
        }
        return Object.values(value).some(v => isValidValue(v));
    }
    return true;
}

function HighlightedItems({ data = {} }) {
    // Filter highlightedKeys to include only those keys that exist in the data and have valid values

    const filteredHighlightedKeys = highlightedKeys.filter(item => {
        const value = data[item.key];
        if (item.key === 'events') {
            return value?.data?.length
            return value && Array.isArray(value.data) && value.data.length > 0;
        } else {
            return item.key in data && isValidValue(value);
        }
    });

    const eventCount = data?.events?.data?.length ?? 0;
    const hasEvents = eventCount > 0;

    return (
        <div className='highlightedItems d-flex flex-row'>

            {filteredHighlightedKeys.map((item, index) => (
                <HighlightedItem {...item} value={data[item.key]} key={index} />
            ))}

            {hasEvents &&
                <div className='d-flex flex-row ps-4'>
                    <HighlightedItem title="Event" key="events" icon="calendar" emoji="📅" value={data.events} />
                    <span className='ms-1'>{eventCount}x</span>
                </div>
            }

        </div>
    );
}

function HighlightedItem({ title, key, icon, emoji, value }) {
    let displayValue = null;
    if (key === 'events') {
        displayValue = value?.data?.length ?? null;
    }
    return (
        <div title={`Der Beitrag erhält: ${title}`} className='highlightedItem'>
            <span className='icon'>{emoji}</span>
            {displayValue != null && <span className='count'>{displayValue}</span>}
        </div>
    );
}

const highlightedKeys = [
    {
        title: "Baustelle",
        key: "detourJson",
        icon: "road",
        emoji: "🚧",
    },
    {
        title: "Einfache Sprache",
        key: "textEasySpeech",
        icon: "text",
        emoji: "🦽",
    },
    {
        title: "Audio-Version",
        key: "audio",
        icon: "audio",
        emoji: "🔊",
    },
    {
        title: "Video-Version",
        key: "youtubeID",
        icon: "video",
        emoji: "📹",
    },
    {
        title: "Instagram",
        key: "instagramText",
        icon: "instagram",
        emoji: "📸",
    },
    {
        title: "Umfrage",
        key: "poll",
        icon: "survey",
        emoji: "📊",
    },
    // {
    //     title: "Event",
    //     key: "events",
    //     icon: "calendar",
    //     emoji: "📅",
    // },
];
